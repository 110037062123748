import React, { useEffect, useState } from "react";
import RegistredCourseCard from "../../components/RegistredCourseCard";
import RegistredCourseDetails from "./RegistredCourseDetails";
import Axios from "../../utilities/Axios";
import logo from "../../images/Spinner.gif";

export default function Index() {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [registredCourse, setRegistredCourse] = useState([]);
  const [loading, setLoading] = useState(false);

  const defaultImage = 'https://www.rushividyabhyasam.org/assets/images/logo/default_image.jpg';
  // Get registered courses
  useEffect(() => {
    getRegistredCourses();
  }, []);

  async function getRegistredCourses() {
    setLoading(true);
    const user_id = localStorage.getItem("uid")
      ? localStorage.getItem("uid")
      : 0;

    const cname = localStorage.getItem("cname");
    console.log("UserID", user_id);
    let body = {
      user_id : user_id,
      country_name : cname
    };

    await Axios.post("courses/get_register_courses_for_user", body)
      .then((res) => {
        let d = res.data.data[0];
        console.log("Registered Courses:", d);
        const filtredData = d.filter((item) => item.register_status === "yes");
        setRegistredCourse(filtredData);
        setLoading(false);
      })
      .catch((err) => {
        setRegistredCourse([]);
        setLoading(false);
        console.log(err);
      });
  }

  // Function to handle course selection and scroll to top
  const handleCourseSelect = (course) => {
    setSelectedCourse(course);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling effect
    });
  };

  return (
    <div className="container-fluid px-1 mt-4">
      {/* Page Title */}
      <div className="bg-white p-3 mb-4 rounded shadow-sm position-relative text-center">
        {selectedCourse ? (
          <>
            <span
              className="text-primary position-absolute"
              style={{
                cursor: "pointer",
                left: "15px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
              onClick={() => setSelectedCourse(null)}
            >
              ← Back to Courses
            </span>
            <h5 className="text-dark m-0">{selectedCourse.course_name}</h5>
          </>
        ) : (
          <h5 className="text-dark m-0">Registered Courses</h5>
        )}
      </div>

      {/* Show Loader while loading */}
      {loading ? (
        <div
          className="loader"
          style={{
            minHeight: "90vh",
            minWidth: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="Loading..."
            style={{
              minHeight: "10vh",
            }}
          />
        </div>
      ) : selectedCourse ? (
        <RegistredCourseDetails courseDetails={selectedCourse} />
      ) : (
        /* Course List */
        <div className="row row-cols-lg-2 row-cols-md-2 row-cols-1 gx-0">
          {registredCourse?.map((course) => (
            <div key={course.id} className="col">
              <RegistredCourseCard
                name={course.course_name}
                price={course.price}
                students={course.students}
                lessons={course.lessons}
                image={course.image}
                id={course.id}
                start_date={course.start_date}
                duration={course.course_duration}
                regstatus={course.register_status}
                status={course.status}
                type={course.type}
                tid={course.teacher_id}
                vimeo_lessons={course.vimeo_lessons}
                onClick={() => handleCourseSelect(course)} // Click to show details and scroll up
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
