import React from "react";
import { useNavigate } from "react-router-dom";

const RegistredCourseCard = ({
  name,
  price,
  students,
  lessons,
  image,
  id,
  duration,
  regstatus,
  status,
  type,
  start_date,
  vimeo_lessons,
  onClick,
}) => {
  const navigate = useNavigate();

  const handleCourseClick = () => {
    if (status !== "coming_soon") {
      navigate(`/courselessons`, {
        state: {
          name,
          price,
          students,
          lessons,
          image,
          id,
          duration,
          regstatus,
          status,
          type,
          start_date
        },
      });
    }
  };

  return (
    <div className="col max-mb-30" data-aos="fade-up">
      <div className="course p-3 shadow-sm rounded bg-white text-center">
        {/* Course Image */}
        <div className="thumbnail">
          <img
            src={image}
            alt="Course Image"
            className="c-s"
            style={{
              cursor: "pointer",
              width: "100%",
              height: "180px",
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
        </div>

        {/* Course Info */}
        <div className="info regi-main mt-3">
          {/* Course Name */}
          <h5 className="mb-1 text-primary fw-bold">{name}</h5>

          {/* Course Type & Price */}
          <p className="mb-1 text-muted">
            {type && <span>({type})</span>} <br />
            {/* {price && <span className="fw-bold text-success">${price} USD</span>} */}
          </p>

          {/* Start Date */}
          <p className="mb-2">
            <strong>Start Date:</strong> {start_date ? start_date : "N/A"}
          </p>

          {/* Lessons, Duration & Button in One Row */}
          <div className="d-flex align-items-center justify-content-between mt-4">
            <div className="d-flex align-items-center">
              <i className="far fa-file-alt me-1 text-primary"></i> {lessons}{" "}
              Lessons
            </div>
            <div className="d-flex align-items-center">
              <i className="far fa-clock me-1 text-success"></i> {duration}
            </div>
            {/* View Lessons Button */}
            {vimeo_lessons == 'true' && (
            <button
              className="btn btn-sm btn-warning d-flex align-items-center"
              onClick={handleCourseClick}
              style={{
                height: "28px",
                fontSize: "13px",
                padding: "4px 10px",
                display: "inline-flex",
                backgroundColor: "#fe880e",
                color: "#fff",
              }}
            >
              <i className="fas fa-play-circle me-1 text-white"></i> View
              Lessons
            </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistredCourseCard;
