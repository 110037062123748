import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Axios from "../../utilities/Axios";

export default function RegistredCourseDetails(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [lessonsData, setlessonsData] = useState([]); 
  const [currentVideo, setCurrentVideo] = useState("");
  const location = useLocation();
  const selectedCourse = location.state || {}; // Retrieve passed props

  useEffect(() => {
    console.log("### selectedCourse : ",selectedCourse);
    getCoursesLessons(selectedCourse.id);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  async function getCoursesLessons(id) {
      setLoading(true);
      const user_id = localStorage.getItem("uid")
        ? localStorage.getItem("uid")
        : 0;
        console.log("UserID", user_id);
      let body = {
        user_id : user_id,
        course_id : id
      };
  
      await Axios.post("courses/get_lessons_for_user", body)
        .then((res) => {
          let d = res.data.data;
          console.log("Courses Lessons :", d);
          setlessonsData(d);
          setCurrentVideo(d[0]?.video_link);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }

  return (
    <div className="bg-white p-3 rounded shadow-sm">
      <div className="row">
        {/* Left Column */}
        <div className="col-md-4">
          <div
            className="card p-3 mb-2 shadow-sm"
            style={{ backgroundColor: "#ffffff", border: "1px solid #dee2e6" }}
          >
            <div className="d-flex justify-content-between mb-2">
              <span
                style={{
                  cursor: "pointer",
                  color: "#fe880e",
                  fontWeight: "normal",
                }}
                onClick={() => navigate("/myaccount")}
              >
                ← Go To Dashboard
              </span>
            </div>

            <h5 className="mb-4 mt-2 text-center">{selectedCourse.name}</h5>

            <div
              style={{
                fontSize: "0.75rem",
                color: "#6c757d",
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "nowrap",
                }}
              >
                
                <div style={{ flex: "1" }}>
                  <strong style={{ fontSize: "0.8rem" }}>
                    &#128218; Lessons:
                  </strong>{" "}
                  <span>{selectedCourse.lessons}</span>
                </div>
              </div>

              <div style={{ marginTop: "5px" }}>

              <div style={{ paddingBottom: "4px" }}>
                  <strong style={{ fontSize: "0.8rem" }}>
                    &#128214; Type:
                  </strong>{" "}
                  <span>{selectedCourse.type}</span>
                </div>
                <div style={{ paddingBottom: "4px" }}>
                  <strong style={{ fontSize: "0.8rem" }}>
                    &#8987; Duration:
                  </strong>{" "}
                  <span>
                    {selectedCourse.duration.charAt(0).toUpperCase() +
                      selectedCourse.duration.slice(1)}
                  </span>
                </div>
               
                <div>
                  <strong style={{ fontSize: "0.8rem" }}>
                    &#128197; Start Date:
                  </strong>{" "}
                  <span>{selectedCourse.start_date}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Contents Section */}
          <div
            className="p-3 rounded shadow-sm"
            style={{ backgroundColor: "#f8f9fa" }}
          >
            <h6 className="mb-3">Lessons</h6>
            {lessonsData.map((item,index) => (
              <div
                key={item.id}
                className="d-flex justify-content-between align-items-center p-2 mb-2 rounded"
                style={{
                  cursor: "pointer",
                  fontSize: "15px", // Increased font size slightly
                  backgroundColor:
                    currentVideo === item.video_link ? "#fff6e5" : "#ffffff", // Light orange for active
                  border:
                    currentVideo === item.video_link
                      ? "1px solid #fe880e"
                      : "1px solid #dee2e6", // Orange border for active
                  transition:
                    "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out", // Smooth transition
                  boxShadow:
                    currentVideo === item.video_link
                      ? "0px 4px 8px rgba(0, 0, 0, 0.1)"
                      : "none", // Glow effect for selected
                }}
                onClick={() => setCurrentVideo(item.video_link)}
                onMouseDown={(e) => {
                  e.currentTarget.style.transform = "scale(0.98)"; // Slight press effect
                }}
                onMouseUp={(e) => {
                  e.currentTarget.style.transform = "scale(1)"; // Reset after click
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.02)"; // Scale up on hover
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)"; // Reset on hover out
                }}
              >
                <div className="d-flex align-items-center">
                  <span
                    style={{
                      color:
                        currentVideo === item.video_link ? "#fe880e" : "#000",
                      marginRight: "8px",
                      fontSize: "18px", // Increased font size of the ▶ icon
                    }}
                  >
                    ▶
                  </span>
                  <span style={{ fontSize: "16px", fontWeight: "500" }}>
                    Chapter {index + 1} : {item.title}
                  </span>{" "}
                  {/* Increased font size of title */}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Right Column */}
        <div className="col-md-8">
          {/* Current Video Title */}
          <div
            style={{
              fontSize: "1rem",
              fontWeight: "bold",
              marginBottom: "8px",
              textAlign: "center",
              padding: "6px",
              backgroundColor: "#f8f9fa",
              borderRadius: "8px",
              border: "1px solid #dee2e6",
            }}
          >
            🎬 Now Playing:{" "}
            {lessonsData.find((item) => item.video_link === currentVideo)?.title ||
              ""}
          </div>

          {/* Video Section */}
          <iframe
            className="w-100 rounded shadow-sm"
            style={{
              height: "490px",
              border: "none",
              marginBottom: "1rem",
            }}
            src={currentVideo}
            title="Course Video"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}
